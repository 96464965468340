import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import User from './views/User.jsx'
import Home from './views/Home.jsx'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/column/public/datasource/152523227' element={<User />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
