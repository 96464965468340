import React from "react";
import '../styles/public.css'

export default () => {
    return (
        <div>
          <div className="header">
            <span className="title">Sienna Song</span>
            <div className="hr" ></div>
            <span className="title2">前端学习笔记</span>
          </div>
        <div className="nav">
          <ul>
            <li>HTML</li>
            <li>CSS</li>
            <li>JAVASCRIPT</li>
          </ul>
        </div>
        <div className="content" />
        <footer style={{position: 'absolute', bottom: 0, width: '100%', textAlign: 'center'}}>
          <a href="https://beian.miit.gov.cn">
            皖ICP备2024042980号
          </a>
        </footer>
      </div>
    )
} 
                                                                          
                                                                          