import React from "react";
import '../styles/public.css'

export default () => {
    return (
        <div>
            <div className="header">
                <span className="title">凯捌智享酒店装饰</span>
                <div className="hr" ></div>
                <span className="title2">招标采购平台</span>
            </div>
            <div className="nav">
                <ul>
                    <li>首页</li>
                    <li>招标采购</li>
                    <li>工作动态</li>
                    <li>办事指南</li>
                </ul>
            </div>
            <div className="content">
                <div className="title">凯捌智享酒店装饰工程中标候选人公示</div>
                <p>发布时间 : 2022-03-08</p>
                <div>一、凯捌智享酒店装饰工程（招标编号：2022-003-002），于2022年03月07日09时30分开标，经评标委员会评审，现将中标候选人公示如下:</div>
                <table cellspacing="0">
                    <tbody>
                        <tr>
                            <th>单位名称</th>
                            <th>投标总报价</th>
                        </tr>
                        <tr>
                            <td>第一中标候选人：安徽铭铸建设工程有限公司</td>
                            <td>6521844.68元</td>
                        </tr>
                    </tbody>
                </table>
                <div>公示期:自2022年03月08日至2022年03月10日</div>
                <div className="importent">招标投标相关各方对上述结果有异议，可在公示期内以书面形式向霍邱县凯捌智享酒店提出。</div>
                <div>二、书面异议材料应当包括以下内容:</div>
                <div>(一）异议人名称、地址和有效联系方式;</div>
                <div>(三）异议事项的基本事实;</div>
                <div>(四）相关请求及主张;</div>
                <div>(五) 有效线索和相关证明材料</div>
                <div className="impotent">书面异议材料必须符合上述要求，且由其法定代表人签字并加盖公章，并附法定代表人及其委托联系人的有效身份证复印件，否则不予接收。</div>
                <div>三、异议材料有下列情形的亦不予接收:</div>
                <div>(—）异议材料不完整的;</div>
                <div>(二）异议事项含有主观猜测等内容且无充分有效证据的;</div>
                <div>(三）对其他投标人的投标文件详细内容异议，无法提供合法来源渠道的。异议人不得以异议为名进行虚假、恶意异议，干扰招标投标活动的正常进行。
                    对于提供虚假材料，以异议为名谋取中标或恶意异议扰乱招标工作秩序的，将报请行政监管部门处理。如公示期内无有效异议，本评审结果即为确定中标人的依据。
                </div>

                <div>特此公示。</div>


                <div className="time">2022年03月08日</div>
            </div>
        </div>
    )
}